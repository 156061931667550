import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useQueryParam, getSearchParams } from "gatsby-query-params";
import { MasterPage } from "../../components";
import Checkout from "../../components/checkout"
import SEO from "../../components/seo";

const Quotes = () => {
    const data = useStaticQuery(graphql`
    query everyMountain {
      site {
        siteMetadata {
          baseUrl
          agent {
            assets {
              image
            }
            id
            name
            phone
            whatsapp
            email
            website
            title
            imageSrc
            calendly
            bio
          }
        }
      }
    }
  `);

    return (
        <MasterPage data={data}>
            <SEO title="Quotes Panel" />
            <Checkout {...getSearchParams()} data={data} />
        </MasterPage>
    );
};

export default Quotes;
